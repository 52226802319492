* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  
  width: 100%;
  max-width: 100vw;
  padding-right: 0px;
  height: 100%;
  
  font-family: 'Poppins', sans-serif;
  background-color: #f8f7ff;
}

body {
  overflow-y: auto !important;
  padding-right: 0px !important;
}